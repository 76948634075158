body {
	overflow: hidden;
}

.leaflet-popup-content {
	margin: 5px 15px  !important;
}

.popup-cluster-site.active {
	font-weight: bold;
	font-size: 0.9rem;
}

.leaflet-popup-tip-container {
	margin-left: -11px !important;
}

.leaflet-popup-content-wrapper {
	margin-left: 30px !important;
}

#grid-dashboard::-webkit-scrollbar-track {
	border: 1px solid black;
	background-color: #F5F5F5;
}

#grid-dashboard::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}


#grid-dashboard::-webkit-scrollbar-thumb {
	background-color: #2D3742;
} 

@keyframes pulse {
	0% { 
		transform: scale(0.9); 
		opacity: 0.5; 
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0); 
	}
	50% { 
		transform: scale(1); 
		opacity: 1; 
	}
	100% { 
		transform: scale(0.9); 
		opacity: 0.5; 
		box-shadow: 0 0 0 35px rgba(0, 0, 0, 0.1);
	}
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-200px);
	}
  60% {
    opacity: 1;
    transform: translateY(20px);
	}
  80% {
    transform: translateY(-10px);
	}
  100% {
    transform: translateY(0);
	}
}